<template>
  
  <div v-if="props.user && props.week" class="week_comment">

<!-- 
    <WeekwideViewCommentSetup
      :setup="props.week.props"     
      :nutrients="props.week.item_germination?.items_nutrient || props.week.item_vegetation?.items_nutrient || props.week.item_flowering?.items_nutrient"
      />  -->

    <!-- <WeekwideViewActions 
      :week="props.week" 
      :week-props="props.week.props" 
      :week-nutrients="props.week.item_germination?.items_nutrient || props.week.item_vegetation?.items_nutrient || props.week.item_flowering?.items_nutrient"
      :user="props.user"
      /> -->


    <!-- <UiTextTruncate :week="props.week" :visibleLines="3" /> -->


    <WeekwideViewAuthorComment
      :week="props.week"
      :item-user="props.user"
      :week-id="props.week.id"
      :comments="props.comments"
      />

    <!-- <div class="date">
      {{ $dayjs(props.week.add_date).fromNow() }}
    </div> -->

  
  </div>
  
</template>

<script setup>
  
// const emits = defineEmits(['click.comment'])

const props = defineProps({
  week: {
    type: Object,
    required: true
  },
  weeks: {
    type: Array,
    required: true
  },
  user: {
    type: Object,
    required: true
  },
  comments: {
    type: Object,
    required: false
  }
})

const changedSetup = computed(() => {

  // return all props

    return props.week.props



  // get prev week
  let prevWeek = props.weeks.filter(week => week.add_date < props.week.add_date).pop()

  // get changed props week.props  {}
  let changed = {}
  if(prevWeek){
    for (const key in props.week.props) {
      if (props.week.props[key] !== prevWeek.props[key]) {
        changed[key] = props.week.props[key]
      }
    }
  }

  if(Object.keys(changed).length === 0){
    return null
  }

  return changed
})

</script>




<style scoped>
 
 .week_comment{
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 0px 0;
  margin-top: 10px;
}
.date{
  font-size: 0.8rem;
  color: #999;
}

/* flex, overflow-x */
.setup{
  display: flex;
  gap: 5px;
  overflow-x: auto;
  padding: 10px 0;
  width: fit-content;
  max-width: 100%;
}

.setup::-webkit-scrollbar {
  display: none;
}

.setup-item{
  padding: 3px 6px;
  border: 1px var(--un-background-color-second) solid;
  border-radius: 5px;
  font-size: 0.8rem;
  flex-shrink: 0;
}
  

@container pb (max-width: 600px) {

  
}



</style>
