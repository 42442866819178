<template>

  <div v-if="props.week?.id" class="comment" @click="tg">

    <div class="ttl">
      Comments
      <span class="cnt">{{ 1 + props.week?.cnt_comments }}</span>
    </div>
    <div class="item">
      <img v-if="props.itemUser?.avatar_little" class="ava" :src="props.itemUser.avatar_little" :alt="props.itemUser.name">
      <div class="text text-ellipsis-3">
        {{ props.week.text ? props.week.text : '—' }}
      </div>

      <!-- <div class="date">
        {{ $dayjs(props.week.add_date).fromNow() }}
      </div> -->

    </div>
    

  </div>
  
</template>

<script setup>
  
const props = defineProps({
  week: {
    type: Object,
    required: true
  },
  itemUser: {
    type: Object,
    required: true
  },
  weekId: {
    type: Number,
    required: true
  },
  comments: {
    type: Number,
    required: false
  }
})


const comment = computed(() => {  
  return props.comments.items_comment.filter(comment => comment.post_id === props.weekId)[0] || null
})


import { inject } from 'vue';

const actionsToggleCommentEvent = inject('actionsToggleCommentEvent');

const tg = (event) => {
  event.stopPropagation();
  actionsToggleCommentEvent({ weekId: props.weekId });
};

</script>




<style scoped>
 
  .comment{
    background-color: var(--un-background-color-gray);
    border-radius: 10px;
    padding: 0.6rem;
    cursor: pointer;
  }
  
  .ttl{
    font-weight: 600;
  }

  .cnt{
    font-size: 0.8rem;
    color: #999;
  }
  
  .item{
    display: flex;
    margin-top: 0.6rem;
    gap: 0.6rem;
    align-items: center;
  }
  
  .ava{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-bottom: auto;
    margin-top: 0;

  }
  
  .text{

  }

 
@container pb (max-width: 600px) {

  
}
</style>
