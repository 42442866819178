<template>


  <div class="slider" v-if="props.week?.items_photo">
    <div class="photos" :size="props.week.items_photo.length">
      <div class="photo" v-for="(photo, photo_key) in props.week.items_photo.slice(0, 6)">
        <img 
          :src="photo.size_m" 
          alt=""
          @click="openGallery(photo.id)"
          >
      </div>
      <div class="more" v-if="props.week.items_photo.length > 6">
        More photos +{{ props.week.items_photo.length - 6 }}
      </div>

    </div>
 
    <div class="dots">
      <div class="dot" v-for="(photo, photo_key) in props.week.items_photo.slice(0, 6)">
        
      </div> 
    </div>

 
  </div>

  <GeneralLightboxVertical 
      v-if="lightboxShow"
      :photo-id="lightboxId" 
      :photos="lightboxPhotos" 
      @close="lightboxShow = false" 
      />
  
</template>

<script setup>
 

var props = defineProps({
  diary: {
    type: Object,
    required: true
  }, 
  week: {
    type: Object,
    required: true
  },
  activeWeek: {
    type: Object,
    required: false
  }, 
})
const emits = defineEmits(['interactWeek']) 

var lightboxShow = ref(false);
var lightboxId = ref(null);
 

const lightboxPhotos = computed(() => {
  var items = [];

  if(!props.week.items_photo) return items;

  for(var p of props.week.items_photo){
    let photo = {};
    if(p.is_video){
      photo.type = 'iframe';
      photo.iframe = '/video/' + p.video_id;
    }          
    photo.id = p.id;
    photo.width = p.width;
    photo.height = p.height;
    photo.base = p.size_big;
    photo.zoom = p.size_big;
    photo.caption = p.comment;
    items.push(photo);        
  }

  // for(var i of props.diary.items_week){
  //   for(var p of i.items_photo){
  //     let photo = {};
  //     if(p.is_video){
  //       photo.type = 'iframe';
  //       photo.iframe = '/video/' + p.video_id;
  //     }          
  //     photo.id = p.id;
  //     photo.width = p.width;
  //     photo.height = p.height;
  //     photo.base = p.size_big;
  //     photo.zoom = p.size_big;
  //     photo.caption = p.comment;
  //     items.push(photo);        
  //   }
  // }
  return items;

  // for(var i of props.data.items_photo){
  //   let photo = {};
  //   if(i.is_video){
  //     photo.type = 'iframe';
  //     photo.iframe = '/video/' + i.video_id;
  //   }          
  //   photo.base = i.size_big;
  //   photo.zoom = i.size_big;
  //   photo.caption = i.comment;
  //   items.push(photo);        
  // }
  // return items;
})

const openGallery = function(key){
  lightboxShow.value = true;
  lightboxId.value = key;
}


onMounted(() => {
   
})

onBeforeUnmount(() => {
 
})
 

</script>

<style scoped>
 
.galpan{

  display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.cover{
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 4/3;
    border-radius: 5px;
}

/* week */

.week{
  font-weight: bold;
  padding-bottom: 4px;
  position: absolute;
  z-index: 1;
  background-color: #ffffffe6;
  border-radius: 5px;
  padding: 4px 12px;
  position: sticky;
  top: 60px;
  width: fit-content;
  margin-top: 12px;
  margin-bottom: -40px;
  font-size: 1rem;
  margin-left: 10px;
}
/* photos */

.photos{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  position: relative;
}
.photos .more{
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ffffffe6;
  padding: 4px 8px;
  border-radius: 5px;
  cursor: pointer;
  /* color: var(--un-text-color-gray); */
  /* font-weight: bold; */
  z-index: 1;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: 0.3s;
  pointer-events: none;
}
.photos .photo{
  display: flex;
  flex-wrap: wrap;
  aspect-ratio: 4/3;
  flex: 1 1 calc(100%/3 - 4px);
  /* remove id need 1 big photo */
  max-width: calc(100%/3 - 4px);
}
.photos .photo img{
  width:100%;
  height:100%;
  object-fit: cover;
  border-radius: 5px;
}

.photos img:only-child {
  flex-basis: 100%;
}

/* Если два изображения */
/* .photos .photo:nth-last-child(2):first-child,
.photos .photo:nth-last-child(2):last-child {
    flex-basis: calc(100%/3 - 4px);
} */

@container pb (max-width: 600px) {
  .photos{
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
    margin-left: -20px;
    width: calc(100% + 40px);
    gap: 0;
  }
  .photos::-webkit-scrollbar {
    display: none;
  }
  .photos .photo{
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    width: 100% !important;
    flex: none;
    max-width: none;
    align-items: center;
    contain: layout;
    justify-content: center;
    position: relative;
    scroll-snap-align: center;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
  .photos .photo img{
    aspect-ratio: 1 / 1;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    border-radius: 0;
  } 
  .week{
    margin-left: 0;
  }
}


/* SIZES GALLERY */
@container pb (min-width: 600px) {
.photos[size="1"] .photo{
  max-width: 100%;
  flex: 1 1 100%;
}
.photos[size="2"] .photo{
  max-width: calc(100%/2 - 4px);
  flex: 1 1 calc(100%/2 - 4px);
}
.photos[size="3"] .photo{
  max-width: calc(100%/3 - 4px);
  flex: 1 1 calc(100%/3 - 4px);
}
.photos[size="4"] .photo{
  max-width: calc(100%/2 - 4px);
  flex: 1 1 calc(100%/2 - 4px);
}
.photos[size="5"] .photo:nth-child(1),
.photos[size="5"] .photo:nth-child(1),
.photos[size="5"] .photo:nth-child(3){
  max-width: calc(100%/3 - 4px);
  flex: 1 1 calc(100%/3 - 4px);
}
.photos[size="5"] .photo:nth-child(4),
.photos[size="5"] .photo:nth-child(5){
  max-width: calc(100%/2 - 4px);
  flex: 1 1 calc(100%/2 - 4px);
}
}



.dots{
  display: flex;
  gap: 4px;
  position: relative;
  margin-top: -20px;
  margin-bottom: 20px;
  
  justify-content: center;
  z-index: 1;
}

.dot{
  width: 7px;
  height: 7px;
  background-color: rgb(159, 159, 159);
  border-radius: 50%;
}

@container pb (min-width: 600px) {
  .dots{
    display: none;
  }
}

</style>
