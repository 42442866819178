<template>



  <div class="comments">
    <div class="cont">
  

      <div class="head">
        <div class="title">
          {{ title }}
        </div>
        <div class="acts">        
          <div class="act" @click="close">
            <i class="icon-close-thin"></i>
          </div>
        </div>
      </div>
        
      
      <WeekwideViewConditions 
        :days="props.week.days"
        :faza="props.week.faza"
        :props="props.week.props"
        />


    </div>
  </div>

</template>


<script setup>
  
const props = defineProps({
  week: {
    type: Object,
    required: false
  },
  title: {
    type: String,
    required: false
  }
});
 

const emits = defineEmits(['close'])
 
const close = function(){
  emits('close');
}




</script>
   




<style scoped>
  
  .comments{
    overscroll-behavior: contain;
    overflow-y: auto;
    height: calc(100% - 70px);
    container: pb / inline-size;
  } 
  .cont{
    max-width: 975px;
    margin: 0 auto;
  }
  .head {
    display: flex;
    width: 100%;
  }
  .head .acts{
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .head .acts .act{
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .head .acts .act:deep(i),
  .head .acts .act i{
    font-size: 1rem;
  }
  .head .title{
    font-size: 1.1rem;
    font-weight: bold;
    padding: 1rem 0;    
  }
  .head .title .cnt{
    font-weight: normal;
  }

  .settings_comments{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0rem 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
   
  .toggler{
    display: flex;
    align-items: center;
    gap: 5px;
  }
.empty_comments{
 display: flex;
 align-items: center;
 justify-content: center;
 min-height: 70px;
 width: 100%;
 color: var(--gd-text-gray-color);
}

  @container pb (max-width: 768px) {
    .settings_comments{
      justify-content: space-between;
    }
  }

</style>
