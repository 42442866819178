<template>
  <div class="pb dia ">  

     
    <template v-if="diary_data && diary_data.id && diary_data.item_user?.id">

      <div class="dat_head">
        <DiarywideViewHead 
          :data="diary_data"
          @removediary="removeDiary"
          @unpublishdiary="publishDiary(1)"
          @publishdiary="publishDiary(0)"
          @follow="follow(1)"
          @unfollow="follow(0)"
          />

        <DiarywideViewConditions :data="diary_data"/> 
      </div>


      <ClientOnly>
        <Teleport to="#app">
          <div class="dat_weeks" :style="{'margin-left': useNavbarOpened().value ? '235px' : '60px'}">
            <DiarywideViewWeeks 
              @choose="chooseWeek" 
              :weeks="diary_data.items_week"   
              :owner="diary_data.item_user.id"   
              :diary="diary_data.id"
              :link="diary_data.link"
              :active="week_active ? week_active.id : null"
              :defweek="week_empty_link"
              :week-empty-link="week_empty_link"
              @create="showWeekModal()"
              />
          </div>
        </Teleport>
      </ClientOnly>

 
      <div class="gal">

        <!-- <DiarywideViewWeeksShort 
            @choose="chooseWeek" 
            :weeks="diary_data.items_week"   
            :owner="diary_data.item_user.id"   
            :diary="diary_data.id"
            :link="diary_data.link"
            :active="week_active ? week_active.id : null"
            :defweek="week_empty_link"
            :week-empty-link="week_empty_link"
            @create="showWeekModal()"
            /> -->

        <DiarywideViewGalleryWeeks
          :diary="diary_data"
          :activeWeek="week_active"
          :comments="comments_data"
          @interactWeek="interactWeek"
          @click.comment="toggleComment"
          />

      </div>

      <div class="dat">
 

        <div class="week-box">


          <div class="scrl">
 

            <ClientOnly>
              <Teleport to="#app">
                <DiarywideViewChooseWeekFaza 
                  :data="diary_data" 
                  :active="active_week_choose"
                  @close="closeWeekModal()"
                  />
              </Teleport>
            </ClientOnly>


            <!-- <NuxtPage 
              :week="week_active" 
              :diary="diary_data"
              @choosecreateweek="showWeekModal"
              @removeweek="removeWeek"
              @updateweek="updateWeek"
              @canceleditweek="cancelEditWeek"
              /> -->

            <!-- <WeekViewLike    
              v-if="showComments && week_active"      
              :addon="diary_data.addon.likes"
              :cnt="week_active.cnt_likes"        
              :id="week_active?.id"
              @like="likeWeek"
              /> -->


            <!-- <div v-show="showComments && week_active && (diary_data.items_question || useAuth().isOwner(diary_data.item_user.id))">

              <div class="divider">
                {{ $t('week_view_grow_questions') }}
              </div>

              <QuestionShortForm
                v-if="useAuth().isOwner(diary_data.item_user.id)"
                :diary="diary_data.id"
                :week="week_active?.id"
                />       

              <DiarywideViewQuestionsList       
                :list="diary_data.items_question"
                />
                
            </div> -->


            <!-- <div class="cpm"></div> -->

            <!-- <div v-show="showComments">

              <CommentWidget
                  :title="$t('diary_info_comments')"
                  :type="'diary'"
                  :content="week_empty_link"
                  />
                
            </div> -->
          </div>

        </div>


      </div>



    </template>

    <template v-else>
      <SkDiaryOpened />
    </template> 

    <WeekViewContextMenu 
      :week="week_active" 
      :diary="diary_data"
      @removediary="removeDiary"
      @publishdiary="publishDiary(0)"
      @unpublishdiary="publishDiary(1)"
      @addweek="showWeekModal"
      @likeweek="likeWeek(1)"
      @unlikeweek="likeWeek(0)"
      @followdiary="follow(1)"
      @unfollowdiary="follow(0)"
      />

      <ClientOnly>
        <Teleport to="#app">

          <div 
            class="overlay-comments"
            v-if="commentShow && commentShowWeekId">
            <CommentWidgetFlowWeek
              :title="$t('journal_view_post_comments')"
              :type="'week'"
              :content="commentShowWeekId"
              :week-text="week_active.text"
              :week-author="diary_data.item_user"
              @close="toggleComment"
              />              
          </div>

          <div 
            class="overlay-comments-dimmer" 
            v-if="commentShow && commentShowWeekId"
            @click="toggleComment"
            ></div>

        </Teleport>
      </ClientOnly>
      
      <!--  -->

      <ClientOnly>
        <Teleport to="#app">

          <div 
          class="overlay-setup"
          v-if="setupShow && week_active.id">
            <WeekwideViewSetupFlow
              :title="'Setup'"
              :week="week_active"
              @close="toggleSetup"
              />
          </div>

        </Teleport>
      </ClientOnly>
      
      
      <!--  -->

      <ClientOnly>
        <Teleport to="#app">

          <div 
          class="overlay-nutrients"
          v-if="nutrientsShow && week_active.id">
            <WeekwideViewNutrientsFlow
              :title="'Nutrients'"
              :week-nutrients="week_active.item_germination?.items_nutrient || week_active.item_vegetation?.items_nutrient || week_active.item_flowering?.items_nutrient"
              @close="toggleNutrients"
              />
          </div>

        </Teleport>
      </ClientOnly>
      


  </div>
</template>




<script setup>

import { provide } from 'vue';

const route = useRoute();
const router = useRouter();
const { $api, $ga, $helper, $popup, $head , $follow} = useNuxtApp();

const props = defineProps({
  in_active_week_choose: {
    type: String,
    required: false
  }
});

const link = ref(route.params.id);
const is_loaded = ref(false);
const commentShow = ref(false);
const commentShowWeekId = ref(null);
const nutrientsShow = ref(false);
const setupShow = ref(false);
const week_active = ref(null);
const active_week_choose = ref(props.in_active_week_choose);
const diary_id = ref($helper.extractLinkId(route.params['id']));
const route_week_id = ref(route.params['week'] ?? null);
const light = ref(route.query['light'] ?? null);
 


const toggleSetup = function() {
  setupShow.value = !setupShow.value;
}
provide('actionsToggleSetupEvent', toggleSetup);


const toggleComment = function(data) {

  commentShow.value = !commentShow.value;
  if(data){ 
    commentShowWeekId.value = data.weekId;
  }


}
provide('actionsToggleCommentEvent', toggleComment);


const toggleNutrients = function() {
  nutrientsShow.value = !nutrientsShow.value;
}
provide('actionsToggleNutrientsEvent', toggleNutrients);


watch(route, (new_route, old_route) => {
  // week_id.value = new_week_id;
  console.log('new_route.params');
  console.log(new_route.params);
  route_week_id = new_route.params['week'] ?? null;
  setActiveWeek(route_week_id);
}, {deep: true})


const week_empty_link = computed(() => {
  var id = null;
  if(diary_data.value?.items_week)
  // return first
  for (const [wkey, w] of diary_data.value.items_week.entries()) {
    if(wkey == 0)
      id = w.id;
  }


  // for (const [wkey, w] of diary_data.value.items_week.entries()) {
  //   if(wkey == diary_data.value.items_week.length - 1)
  //     id = w.id;
  // }
  return id;
})



const showComments = computed(() => {
  for (const m of route.matched)
    if (m.path.indexOf('edit') >= 0)
      return false;   
  return true;
});

const interactWeek = function(week) {
  // router.push({
  //   path: '/diaries/' + diary_data.value.link + '/week/' + week.id
  // });
  week_active.value = week;
}

function removeWeek(id) {  
  
  if(confirm('Delete week?'))
    $api.deleteWeek(getnumDiary(), id)
    .then(response => { 
      $popup.success('Success');
      
      for (let w in diary_data.value.items_week)
        if (diary_data.value.items_week[w].id == id) 
          diary_data.value.items_week.splice(w, 1);
        
      setActiveWeek();
    })
    .catch(error => {
      $popup.error('error');
    });
}
 

function setActiveWeek(id) {

  console.log('setActiveWeek');
  console.log(id);
  console.log(diary_data.value.items_week);
  console.log(week_empty_link.value);

  if(!diary_data.value?.items_week)
    return;

  week_active.value = diary_data.value.items_week.filter( (w) => {
    if(!id || typeof id == 'undefined'){
      return week_empty_link.value == w.id;
    }else{
      return w.id == id
    }
  })[0];

  console.log(week_active.value);

}

function showWeekModal() {
  active_week_choose.value = true;
}

function closeWeekModal() {
  active_week_choose.value = false;
}

function chooseWeek(week) {
  console.log('chooseWeek');
  week_active.value = week;
}

function getnumDiary() {
  return link.value.split('-')[0];  
}



async function updateWeek(edited_week_id) {  
  
  diary_data.value = await $api.getDiary(diary_data.value.id)  

  redirectToWeek(edited_week_id); 

}


function removeDiary() {
  if(confirm('Delete diary?')){
    $api.deleteDiary(diary_data.value.id)
    .then(response => { 
      $popup.success('Success');
    })
    .catch(error => {
      $popup.error('error');
    });
  }
}

function publishDiary(state) {
  diary_data.value.is_hidden = state;
   $api.patchDiary(diary_data.value.id, {
    is_hidden: state
  })
    .then(response => { 
      $popup.success('Success');
    })
    .catch(error => {
      diary_data.value.is_hidden = !state;
      $popup.error('error');
    });
}

function cancelEditWeek(edited_week_id) {
  redirectToWeek(edited_week_id);
}


function redirectToWeek(edited_week_id) {

  if(!edited_week_id || edited_week_id == week_empty_link.value){
    router.push({
      path: '/diaries/' + diary_data.value.link
    });
  }else{
    router.push({
      path: '/diaries/' + diary_data.value.link + '/week/' + edited_week_id
    });
  }

}

function likeWeek(id) {

  

  if(diary_data.value.addon.likes.indexOf(id) >= 0){
    diary_data.value.addon.likes.splice(diary_data.value.addon.likes.indexOf(id), 1);
    week_active.value.cnt_likes--;
  }else{
    diary_data.value.addon.likes.push(id);
    week_active.value.cnt_likes++;
  }
}

const follow = function(state) {  
  $follow.toggle('diary', diary_data.value.id, state, {
    type: 'diary',
    id: diary_data.value.id,
    avatar: diary_data.value.avatar_small,
    name: diary_data.value.name,
    link: diary_data.value.link
  })
  .then((response) => {    
    diary_data.value.addon.follow = state; 
    diary_data.value.cnt_followers += state ? 1 : -1;
  })
  .catch((error) => {
    $popup.error('Error')
  });
}
 
// const diary_data = ref(null);
// const diary_addon = ref(null);

const loadData = async (id) => {
  let data = await $api.getDiary(id);  
  is_loaded.value = true;
  // console.log('set addon')
  // console.log(data.addon)
  // diary_addon.value = data.addon;
  return data;
}

const loadComments = async (id) => {
  const response = await $api.getCommentsSummary(
    diary_id.value,
    'diary'
  )  
  return response;  
}



const { pending: is_loading,  data: diary_data } = await useLazyAsyncData('diary_data', async () => await loadData(diary_id.value))



const { pending: is_loading_comments,  data: comments_data } = await useLazyAsyncData('comments_data', async () => await loadComments(diary_id.value))


// watch(diary_data_object, ( new_diary_data_object) => {

//   if(new_diary_data_object)
//     diary_data.value = new_diary_data_object.data;
//   // diary_data.value = diary_data_object.value.data;
//   // console.log(diary_data_object);
//   // console.log(diary_data);
// })


if(diary_data.value)
  setActiveWeek(route_week_id.value);


watch(diary_data, async ( new_diary_data) => {
  console.log('route_week_id');
  console.log(route_week_id.value);

  setActiveWeek(route_week_id.value);
  useHead(useNuxtApp().$head.getForDiary(diary_data.value, week_active.value));
})




useHead(useNuxtApp().$head.getForDiary(diary_data.value, week_active.value));

onUnmounted(() => {
  if(!diary_data.value)
    return;
  diary_data.value = null;
})



// theme margins
onMounted(() => {
  usePageBottomMargin().value = 90;
})

onUnmounted(() => {
  usePageBottomMargin().value = 0;
})




 


</script>


<style scoped>

.scrl{
  overflow-y: auto;
  max-height: calc(100vh - 130px);
}

.dia{
  display: flex;
  align-items: flex-start;
  gap: 0 1rem;
  flex-wrap: wrap;
  max-width: 935px!important;
}
.gal{ 
  width: calc(100%);
  margin-bottom: 100px;
}
.dat_head{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0 1rem;
}
/* .dat{
  width: 320px;
  max-width: 400px;
  flex-shrink: 0;
  container: pb / inline-size; 
  align-self: stretch;
} */
.dat_weeks{
  position: fixed;
  bottom: 5px;
  max-width: 70%;
  left: 0;
  padding: 10px;
  z-index: 2;
  right: 0;
   /* max-width: 80vw; */
    left: 40%;
    transform: translateX(-40%);
}

.week-box{
  position: sticky;
  top:50px;
  max-height: calc(100vh - 30px);  
}

.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.gal .report_days_sticky{
  display: none;
}

.gal .report_header{
  display: none;
}

  
@container pb (max-width: 600px) {

  .gal{

    width: 100%;
  }

  .gal .report_days_sticky{
    display: flex;
  }

  .gal .report_days_sticky{
    margin-left: -2rem;
    width: calc(100% + 4rem);
    padding: 0.5rem 2rem;
  }

  .gal .report_header{
    display: flex;
  }
  .dat{
    display: none
  }
}

@media (max-width: 768px) {

  .dat_weeks{

    bottom: 5px;
    max-width: 100%;
    left: 0;
    padding: 10px;
    z-index: 2;
    right: 0;
    transform: translateX(0%);
    margin-left: 0!important;
  }

}

/* overlay-comments */

.overlay-comments{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--un-background-color);
  z-index: 999999;  
  max-height: 70vh;
  padding: 1rem;
  box-shadow: 0 0 20px #00000012;

  display: flex;
  flex-direction: column;
}
/* dimmer */
.overlay-comments-dimmer{
  content: '';
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #00000059;
  z-index: 23333;
}
 

/* overlay-setup */

.overlay-setup{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--un-background-color);
  z-index: 999999;  
  max-height: 60vh;
  padding: 1rem;
  box-shadow: 0 0 20px #00000059;
}

.overlay-setup :deep(.comments){
  max-height: 400px;
}

/* overlay-setup */

.overlay-nutrients{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--un-background-color);
  z-index: 999999;  
  max-height: 60vh;
  padding: 1rem;
  box-shadow: 0 0 20px #00000059;
}

.overlay-nutrients :deep(.comments){
  max-height: 400px;
}

</style>
